import React from "react"
import Slider from "react-slick"
import classNames from "classnames"

function Slick({
  children,
  slideToShow,
  slideToScroll,
  shadow,
  speed,
  onHover,
}) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideToShow,
    slidesToScroll: slideToScroll,
    autoplay: true,
    autoplaySpeed: speed,
    focusOnSelect: false,
    pauseOnHover: onHover || false,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Slider
      className={classNames("mb-2", {
        "is-shadow": shadow,
      })}
      {...settings}
    >
      {children}
    </Slider>
  )
}

export default Slick
